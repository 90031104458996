import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'gatsby';
import {Helmet} from 'react-helmet';
import appleStoreImage from '../../static/apple-store-footer-image.png';
import playStoreImage from '../../static/play-store-footer-image.png';
import linkedInLogo from '../../static/images/social/linkedin-white.png';
import facebookIcon from '../../static/images/social/new/facebook.svg';
import linkedinIcon from '../../static/images/social/new/linkedin.svg';
import twitterIcon from '../../static/images/social/new/twitter.svg';
import linkIcon from '../../static/images/link@2x.png';
import '../styles/module-certificate.scss';
import {SvgLoader, SvgProxy} from 'react-svgmt';
import {jsPDF} from 'jspdf';
import 'svg2pdf.js';
import {getParameterByName} from '../Templates/Shared/Utils';
import * as trackAction from '../Application/Shared/TrackActionService';
import {Button, Container} from 'react-bootstrap';
import * as apiService from '../api';
import {navigate} from 'gatsby';
import ShawacademyLogo from '../../static/images/logo/logo-new.inline.svg';
import englishCoursesJson from '../../data/englishCourses.json';
import nonEnglishCoursesJson from '../../data/nonEnglishCourses.json';

/**
 * @return {*}
 */
function ModuleCertificate() {
  const publicCertUrl = `/public/module-certificate`;
  const allCoursesList = [...englishCoursesJson?.edges,
    ...nonEnglishCoursesJson?.edges];
  const locale = 'en';
  const svgRef = useRef(null);
  const linkRef = useRef(null);
  const [showError, setError] = useState(false);
  const [isActive, setActive] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [formId, setFormId] = useState('');
  const [responseId, setResponseId] = useState('');
  const [issueYear, setIssueYear] = useState('');
  const [issueMonth, setIssueMonth] = useState('');
  const [moduleData, setModuleData] = useState(null);
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  today = mm + '/' + dd + '/' + yyyy;

  const getCredentials = () => {
    const formId = getParameterByName('form_id');
    const responseId = getParameterByName('response_id');
    return {
      formId,
      responseId,
    };
  };

  const fetchCertificateData = () => {
    const {formId, responseId} = getCredentials();
    setFormId(formId);
    setResponseId(responseId);
    new apiService.default.Certificate()
        .getCertificateData(formId, responseId).then((res) => {
          const leadData = ({...res.data, date: today});
          if (!leadData['user_name']) {
            throw new Error;
          }
          setActive(true);
          setLeadData(leadData);
          const dateArray = leadData?.date?.split('/');
          setIssueYear(dateArray[2]);
          setIssueMonth(dateArray[0]);
          const course = allCoursesList.filter(
              (course) =>
            course?.node?.courseslug === leadData?.course_slug)?.[0];
          setModuleData(course?.node?.
              modules[parseInt(leadData?.module_number) - 1]);
        }).catch((err) => {
          console.log(err);
          setError(true);
        });
  };

  useEffect(() => {
    fetchCertificateData();
    trackAction.pageViewAction('CertificatePage', locale);
  }, []);

  const generatePDFData = () => {
    const data = {
      studentId: leadData['student_id'],
      courseSlug: leadData['course_slug'],
      moduleNumber: leadData['module_number'],
      contentType: 'Certificate',
      resourceType: 'Certificate',
      fileName: 'certificate.pdf',
    };
    trackAction.downloadClickAction(data);
    const certificateRef = svgRef.current.querySelector('svg');
    const jsPdfInstance = new jsPDF();
    jsPdfInstance.svg(certificateRef).then((res) => {
      res.save('certificate.pdf');
    });
  };

  const handleCopyToClipboard = () => {
    triggerShareCertificateAction('Copy Link');
    const linkRefObject = linkRef?.current;
    linkRefObject.select();
    document.execCommand('copy');
  };

  const linkedInUrl = 'https://www.linkedin.com/profile/add?startTask=';
  const certificateUrl = encodeURIComponent(
      `${process.env.ShawWebsiteDomain}${publicCertUrl}/` +
    `?form_id=${formId}&response_id=${responseId}`);

  const navigateToRecordingsPage = () => {
    const regId = leadData['course_regid'];
    const urlToNavigate =
      `/app/${locale}/v2/registrations/${regId}/modules/1`;
    navigate(urlToNavigate);
  };

  const triggerShareCertificateAction = (cta) => {
    trackAction.ctaClickAction({
      'studentId': leadData['student_id'],
      cta,
      'action': 'shareCertificate',
    });
  };

  const shareCertificate = (cta) => {
    triggerShareCertificateAction(cta);
    let urlToNavigate = ``;
    if (cta === 'facebook') {
      urlToNavigate = `https://www.facebook.com/sharer/sharer.php?` +
        `&u=${certificateUrl}`;
    } else if (cta === 'linkedin') {
      urlToNavigate = `https://www.linkedin.com/sharing/share-offsite?mini=true` +
        `&url=${certificateUrl}`;
    } else if (cta === 'twitter') {
      urlToNavigate = `https://twitter.com/intent/tweet?` +
        `&url=${certificateUrl}`;
    }
    window.open(urlToNavigate);
  };

  const addToLinkedInProfile = () => {
    trackAction.ctaClickAction({
      'studentId': leadData['student_id'],
      'cta': 'LinkedIn',
      'action': 'AddToProfile',
    });
    const urlToNavigate =
      `${linkedInUrl}CERTIFICATION_NAME` +
      `&name=${leadData?.course_name}` +
      `&organizationId=5038433` +
      `&issueYear=${issueYear}&issueMonth=${issueMonth}` +
      `&expirationYear=${parseInt(issueYear) + 5}` +
      `&expirationMonth=${issueMonth}` +
      `&certUrl=${certificateUrl}` +
      `&certId=${responseId}`;
    window.open(urlToNavigate);
  };

  return (
    <div className="sh-module-certificate">
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>
      <header className={'header'}>
        <Container className={'container'}>
          <div className={'headerTopSection'}>
            <div className={'headerLogo'}>
              <Link to={`/app/${locale}/`}>
                <ShawacademyLogo />
              </Link>
            </div>
          </div>
        </Container>
      </header>
      <main>
        {!showError ? <div className='main-wrapper'>
          <div className='breadcrumb-wrapper'>
            <div
              onClick={navigateToRecordingsPage}
              className='icon-wrapper library-shaw-icon-new'>
              <i className='icon-new-Left-Arrow-6'></i>
            </div>
            <div className='module-info-wrapper'>
              <p className='module-info-breadcrumb'>
                Module <span id="moduleNumber">
                  {leadData['module_number']}
                </span> certificate
              </p>
              <p id="moduleHeaderName" className='module-name-breadcrumb'>
                {leadData['module_name']}
              </p>
            </div>
          </div>
          <div className='main-content-wrapper'>
            <div ref={svgRef} className='svg-wrapper'>
              <SvgLoader
                path={`/images/module-certificate/certificate-${locale}.svg`}>
                <SvgProxy
                  selector="#resultPath">
                  {leadData['grade']}
                </SvgProxy>
                <SvgProxy
                  selector="#datePath">
                  {leadData['date']}
                </SvgProxy>
                <SvgProxy
                  selector="#namePath">
                  {leadData['user_name']}
                </SvgProxy>
                <SvgProxy
                  selector="#moduleNamePath">
                  {
                    moduleData?.certificatedisplayname ?
                      moduleData?.certificatedisplayname :
                      leadData['module_name']
                  }
                </SvgProxy>
              </SvgLoader>
            </div>
            <div className='content-wrapper'>
              <div className='shareCertificateTitle'>
                Share your Diploma certificate
              </div>
              <p className='shareCertificateText'>
                Add more value to your profile by sharing
                verifiable diploma on your LinkedIn profile.
              </p>
              <Button id="downloadPdf"
                className={`download-cta ${isActive ? 'active' : ''}`}
                onClick={generatePDFData}>
                <img src="/images/module-certificate/pdf.svg" />
                <p>Download PDF</p>
              </Button>
              <a
                className='linkedInShareLink'
                onClick={addToLinkedInProfile}
              >
                <button
                  className='linkedInShareButton'
                >
                  <img
                    className='linkedInShareLogo'
                    src={linkedInLogo}
                    alt='LinkedIn share'
                  />
                  <p className='linkedInShareText'>Add to Profile </p>
                </button>
              </a>
              <p className='shareCertificateTitle' style={{fontSize: '13px'}}>
                Share your certificate on
              </p>
              <div className='socialMediaIconsConatiner'>
                <a
                  onClick={() => shareCertificate('facebook')}
                >
                  <p className='socialIconShare'>
                    <img src={facebookIcon} alt='facebook-icon' />
                  </p>
                </a>
                <a
                  onClick={() => shareCertificate('linkedin')}
                >
                  <p className='socialIconShare'>
                    <img src={linkedinIcon} alt='linkedin-icon' />
                  </p>
                </a>
                <a
                  onClick={() => shareCertificate('twitter')}
                >
                  <p className='socialIconShare'>
                    <img src={twitterIcon} alt='twitter-icon' />
                  </p>
                </a>
              </div>
              <p className='shareCertificateTitle' style={{fontSize: '13px'}}>
                Share your certificate link
              </p>
              <div className='copyButtonContainer'>
                <img src={linkIcon} alt='link-icon' className='linkIcon' />
                <input
                  className='certLinkInput'
                  ref={linkRef}
                  value={decodeURIComponent(certificateUrl)}
                />
                <button
                  className='copyButton'
                  onClick={handleCopyToClipboard}
                  style={{outline: 'none'}}
                >Copy</button>
              </div>
            </div>
          </div>
        </div> :
          <div className='error-wrapper'>
            Something went wrong. Please Try again later...
          </div>}
      </main>
      <footer id='footer' className='footer'>
        <Container>
          <div className="sh-internal-wrapper">
            <div className="sh-footer-content">
              <div className="mobileAppText">
                Get the mobile app
              </div>
              <div className="sh-social-icons">
                <a href="https://itunes.apple.com/app/apple-store/id1240131410?pt=118508823&ct=popUpBanner&mt=8"
                  className="sh-icon-holder">
                  <img src={appleStoreImage} alt="apple store icon" />
                  <p>App Store</p>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.shawacademy.app" className="sh-icon-holder">
                  <img src={playStoreImage} alt="play store icon" />
                  <p>Play Store</p>
                </a>
              </div>
            </div>
            <div className="sh-social-privacy">
              <div>Copyright © 2023 Shaw Academy, Pvt. Ltd.</div>
              <ul>
                <a href={'https://www.shawacademy.com/terms/'}
                  target="__blank">Terms of Use | </a>
                <a href={'https://www.shawacademy.com/privacy/'}
                  target="__blank">Privacy Policy</a>
              </ul>
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
}

ModuleCertificate.defaultProps = {
  data: {},
};

export default ModuleCertificate;
