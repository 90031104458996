import cookie from 'react-cookies';
const studentId = cookie.load('shawacademy_leadId');

export const ctaClickAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'cta-click-action',
      'cta-click-param': params,
    });
  }, 50);
};

export const lessonClickAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'lesson-click-action',
      'lesson-click-param': params,
    });
  }, 50);
};

export const lessonOnTime = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'lesson-ontime-action',
      'lesson-ontime-param': params,
    });
  }, 50);
};

export const trackWebinarAttendance = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'track-action',
      'event-param': {
        'event': 'webinar_attendance',
        'parameter': params,
      },
    });
  }, 50);
};

export const popUpAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'popup-action',
      'popup-param': params,
    });
  }, 50);
};

export const assignmentClickAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'assessment-click-action',
      'assessment-click-param': params,
    });
  }, 50);
};

export const assignmentCompleteAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'assessment-complete-action',
      'assessment-complete-param': params,
    });
  }, 50);
};

export const addonPurchaseAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'addon-purchase-action',
      'event-param': params,
    });
  }, 50);
};


export const trackPurchaseFailure = (params) => {
  window.dataLayer.push({
    'event': 'addon-purchase-failed',
    'addon-purchase-failed-param': params,
  });
};

export const webinarRatingEvent = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'lesson-rate-action',
      'lesson-rate-param': params,
    });
  }, 50);
};


export const pageViewAction = (pageKind, locale) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'view-page-action',
      'view-page-param': {
        'studentId': studentId,
        'pageKind': pageKind,
        'locale': locale,
      },
    });
  }, 50);
  cookie.save('pageKind', pageKind, {
    path: '/',
  });
};

export const downloadClickAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'download-click-action',
      'download-click-param': {
        'studentId': studentId,
        ...params,
      },
    });
  }, 50);
};

export const pointsRecieved = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'points-recieved-action',
      'points-recieved-param': {
        'studentId': studentId,
        ...params,
      },
    });
  }, 50);
};

export const triggerApiErrorAction = (pageKind, status) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'api-error-action',
      'api-error-param': {
        'pageKind': pageKind,
        'status': status,
      },
    });
  }, 0);
};


export const loggedInAction = (locale) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'logged-in-action',
      'logged-in-param': {
        'locale': locale,
      },
    });
  }, 50);
};

export const registrationAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'registration-action',
      'registration-param': {
        'studentId': studentId,
        'action': 'Rescheduled',
        'moduleNumber': params.moduleNumber,
        'courseSlug': params.courseSlug,
        'facultySlug': params.facultySlug,
        'locale': params.locale,
        'lessonNumber': params.lessonNumber || null,
        'rescheduleType': params.rescheduleType ? params.rescheduleType : null,
        'Goal': params.Goal ? params.Goal : 2,
        'scheduleType': params?.scheduleType,
      },
    });
  }, 50);
};

export const registerModuleAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'registration-action',
      'registration-param': {
        'studentId': studentId,
        'action': 'RegisteredModule',
        'moduleNumber': params.moduleNumber,
        'courseSlug': params.courseSlug,
        'facultySlug': params.facultySlug,
        'locale': params.locale,
        'Goal': params.Goal,
        'pageKind': params.pageKind,
        'scheduleType': params.scheduleType,
      },
    });
  }, 50);
};

export const subscriptionChangeAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'subscription-change-action',
      'subscription-change-param': {
        'studentId': studentId,
        'action': params.action,
        'planId': params.planId,
      },
    });
  }, 50);
};
export const cancellationAttemptedAction = (params) => {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'cancellation-attempted-action',
      'cancellation-attempted-param': {
        'studentId': studentId || '',
        'stepNumber': params.stepNumber,
        'offering': params.offering,
        'reason': params.reason,
        'behaviourType': params.behaviourType,
        'accountType': params.accountType,
        'daysFromBilling': params.daysFromBilling,
        'moduleCount': params.moduleCount,
      },
    });
  }, 50);
};
export const cancellationOutcomeAction = (params) => {
  const eventName = params.stepNumber == 3 ?
              'lost' : 'saved';
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'cancellation-outcome-action',
      'cancellation-outcome-param': {
        'outcome': eventName,
        'studentId': studentId,
        'stepNumber': params.stepNumber,
        'offering': params.offering,
        'reason': params.reason,
        'behaviourType': params.behaviourType,
        'accountType': params.accountType,
        'daysFromBilling': params.daysFromBilling,
        'moduleCount': params.moduleCount,
      },
    });
  }, 50);
};
export const searchEnteredAction = (params) => {
  setTimeout(() => {
    window?.dataLayer?.push({
      'event': 'search-entered-action',
      'search-entered-param': params,
    });
  }, 50);
};
